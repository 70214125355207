'use strict';

import scss from '../css/sass.scss';

import $ from "jquery";
import scrollTo from 'jquery-scroll';
import LazyLoad from "vanilla-lazyload";

require('bootstrap');

function myFunction() {
    var x = document.getElementById("myDIV");
    if (x.style.display === "none") {
        x.style.display = "block";
    } else {
        x.style.display = "none";
    }
}

const logEvent = (eventName, element) => {
	console.log(
		Date.now(),
		eventName,
		element.getAttribute("data-src"),
		element.getAttribute("src")
	);
};


const lazyLoadOptions = {
	elements_selector: ".lazy",


	callback_enter: element => {
		logEvent("ENTERED", element);
	},
	callback_load: element => {
		logEvent("LOADED", element);
	},
	callback_set: element => {
		logEvent("SET", element);
	},
	callback_error: element => {
		logEvent("ERROR", element);
		element.src = "https://placehold.it/220x280?text=Placeholder";
	}
};




$( document ).ready(function() {
    var myLazyLoad = new LazyLoad({
        elements_selector: ".lazy",
        load_delay: 2000,
        callback_enter: element => {
            logEvent("ENTERED", element);
        }
    });
});


console.log("Test");


// $(window).scroll(function() {    
//     var scroll = $(window).scrollTop();    
//     if (scroll <= 500) {
//         $(".clearheader").addClass("darkHeader");
//     }
// }
// $(window).scroll(function () {
//     var scroll = $(window).scrollTop();
//         // $(".system-image").addClass("darkHeader");
//         $('.leading-section')
//             .find('.system-image')
//             .animate({
//                 top: 20
//             }, 2000);

//     if (scroll >= 330) {
//         // $(".active-system-section").addClass("activeimage");
//         $('.intractive-display')
//             .find('.active-system-section')
//             .animate({
//                 top: 35
//             }, 4000);
//     }
//     if (scroll >= 300) {
//         // $(".active-system-section").addClass("activeimage");
//         $('.rtf-feature-section')
//             .find('.feature-section-image')
//             .animate({
//                 top: 0
//             }, 3000);
//     }
// });

// $(document).scroll(function () {
//     var y = $(this).scrollTop();
//     if (y > 150) {
//         $('.intractive-display').fadeIn();
//     } else {
//         $('.intractive-display').fadeOut();
//     }
// });

// var $animation_elements = $('.animation-element');
// var $window = $(window);

// function check_if_in_view() {
//     var window_height = $window.height();
//     var window_top_position = $window.scrollTop();
//     var window_bottom_position = (window_top_position + window_height);

//     $.each($animation_elements, function () {
//         var $element = $(this);
//         var element_height = $element.outerHeight();
//         var element_top_position = $element.offset().top;
//         var element_bottom_position = (element_top_position + element_height);

//         //check to see if this current container is within viewport
//         if ((element_bottom_position >= window_top_position) &&
//             (element_top_position <= window_bottom_position)) {
//             $element.addClass('in-view');
//         } else {
//             $element.removeClass('in-view');
//         }
//     });
// }

// $window.on('scroll resize', check_if_in_view);
// $window.trigger('scroll');

$(document).ready(function () {
    var headerHeight = $('header').outerHeight();

    $('.scrollTo').click(function (e) {
        var linkHref = $(this).attr('href');

        $('html, body').animate({
            scrollTop: $(linkHref).offset().top
        }, 1000);
        e.preventDefault();
    });

});

$(document).ready(function () {
    var headerHeight = $('header').outerHeight();

    $('.scrollTo').click(function (e) {
        var linkHref = $(this).attr('href');

        $('html, body').animate({
            scrollTop: $(linkHref).offset().top
        }, 1000);
        e.preventDefault();
    });

});


$(document).ready(function () {
    $(".navbar-nav li a").click(function (event) {
        $(".navbar-collapse").collapse('hide');
    });
});

$('body').scrollspy({ target: '.navbar' })

// This code for top scrolling

$(window).scroll(function () {
    if ($(this).scrollTop() >= 180) {        // If page is scrolled more than 50px
        $('#return-to-top').fadeIn(200);
    } else {
        $('#return-to-top').fadeOut(200);
    }
});
$('#return-to-top').click(function () {      // When arrow is clicked
    $('body,html').animate({
        scrollTop: 0                       // Scroll to top of body
    }, 500);
});


